import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DefaultLayout from '../../Layout/DefaultLayout';

export default function CookieUsePage() {
  return (
    <DefaultLayout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h3" component="h1" align="center">
          <FormattedMessage id="ADAMTV_COOKIE_USE" />
        </Typography>
        <Typography align="center" m={1} mb={4}>
          <FormattedMessage id="LAST_UPDATED" />
          :  Jan 9, 2022
        </Typography>
        TODO
        <br />
        <br />
      </Box>
    </DefaultLayout>
  );
}
