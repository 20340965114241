import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DefaultLayout from '../../Layout/DefaultLayout';

const numberOfDaysToNegotiation = '60';
const adrUrl = 'www.adr.org';
const adrPhoneNumber = '800-778-7879';
const amendmentEffectiveDays = '30';

export default function TermsOfUsePage() {
  return (
    <DefaultLayout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h3" component="h1" align="center">
          <FormattedMessage id="ADAMTV_TERMS_OF_USE" />
        </Typography>
        <Typography align="center" m={1} mb={4}>
          <FormattedMessage id="LAST_UPDATED" />
          :  Jan 9, 2022
        </Typography>
        <FormattedMessage id="TERMS_INTROa" />
        {' '}
        <FormattedMessage id="TERMS_INTROb" />
        <br />
        <br />
        <FormattedMessage id="TERMS_INTROc" />
        <br />
        <br />
        <FormattedMessage id="TERMS_INTROd" />
        {' '}
        <FormattedMessage id="TERMS_INTROe" />
        <br />
        <br />
        <FormattedMessage id="TERMS_INTROf" />
        {' '}
        <FormattedMessage id="TERMS_INTROg" />
        <br />
        <br />
        <ol>
          {/* 1 */}
          <li><strong><FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US" /></strong></li>
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L1a" />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L1b" />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L1c" />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L1d" />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L1e" />
          <br />
          <br />
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L2a" />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L2b" />
          <br />
          <br />
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L3a" />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L3b" values={{ amendmentEffectiveDays }} />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L3c" />
          {' '}
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L3d" />
          <br />
          <br />
          <FormattedMessage id="TERMS_CONTRACT_BETWEEN_YOU_AND_US_L4" />
          <br />
          <br />
          {/* 2 */}
          <li><strong><FormattedMessage id="TERMS_THE_COMPANY_SERVICES" /></strong></li>
          <FormattedMessage id="TERMS_THE_COMPANY_SERVICES_L1a" />
          {' '}
          <FormattedMessage id="TERMS_THE_COMPANY_SERVICES_L1b" />
          {' '}
          <FormattedMessage id="TERMS_THE_COMPANY_SERVICES_L1c" />
          <br />
          <br />
          <FormattedMessage id="TERMS_THE_COMPANY_SERVICES_L2a" />
          {' '}
          <FormattedMessage id="TERMS_THE_COMPANY_SERVICES_L2b" />
          {' '}
          <FormattedMessage id="TERMS_THE_COMPANY_SERVICES_L2c" />
          {' '}
          <FormattedMessage id="TERMS_THE_COMPANY_SERVICES_L2d" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_CONTENT_AND_SOFTWARE_LICENSE" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_CONTENT_AND_SOFTWARE_LICENSE_L1" />
          <br />
          <br />
          <FormattedMessage id="TERMS_CONTENT_AND_SOFTWARE_LICENSE_L3a" />
          {' '}
          <FormattedMessage id="TERMS_CONTENT_AND_SOFTWARE_LICENSE_L3b" />
          {' '}
          <FormattedMessage id="TERMS_CONTENT_AND_SOFTWARE_LICENSE_L3a" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_DISCLAIMERS_AND_LIMITATION_ON_LIABILITY" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_DISCLAIMERS_AND_LIMITATION_ON_LIABILITY_L1" />
          <br />
          <br />
          <FormattedMessage id="TERMS_DISCLAIMERS_AND_LIMITATION_ON_LIABILITY_L2a" />
          {' '}
          <FormattedMessage id="TERMS_DISCLAIMERS_AND_LIMITATION_ON_LIABILITY_L2b" />
          <br />
          <br />
          <FormattedMessage id="TERMS_DISCLAIMERS_AND_LIMITATION_ON_LIABILITY_L3" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_CHANGES_TO_COMPANY_SERVICES" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_CHANGES_TO_COMPANY_SERVICES_L1a" />
          {' '}
          <FormattedMessage id="TERMS_CHANGES_TO_COMPANY_SERVICES_L1b" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_ADDITIONAL_RESTRICTIONS_ON_USE_OF_COMPANY_SERVICES" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_ADDITIONAL_RESTRICTIONS_ON_USE_OF_COMPANY_SERVICES_L1a" />
          {' '}
          <FormattedMessage id="TERMS_ADDITIONAL_RESTRICTIONS_ON_USE_OF_COMPANY_SERVICES_L1b" />
          {' '}
          <FormattedMessage id="TERMS_ADDITIONAL_RESTRICTIONS_ON_USE_OF_COMPANY_SERVICES_L1c" />
          {' '}
          <FormattedMessage id="TERMS_ADDITIONAL_RESTRICTIONS_ON_USE_OF_COMPANY_SERVICES_L1d" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_THIRD_PARTY_SERVICES_AND_CONTENT" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_THIRD_PARTY_SERVICES_AND_CONTENT_L1a" />
          {' '}
          <FormattedMessage id="TERMS_THIRD_PARTY_SERVICES_AND_CONTENT_L1b" />
          {' '}
          <FormattedMessage id="TERMS_THIRD_PARTY_SERVICES_AND_CONTENT_L1c" />
          <br />
          <br />
          <FormattedMessage id="TERMS_THIRD_PARTY_SERVICES_AND_CONTENT_L2a" />
          {' '}
          <FormattedMessage id="TERMS_THIRD_PARTY_SERVICES_AND_CONTENT_L2b" />
          {' '}
          <FormattedMessage id="TERMS_THIRD_PARTY_SERVICES_AND_CONTENT_L2c" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_MOBILE_NETWORKS" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_MOBILE_NETWORKS_L1a" />
          {' '}
          <FormattedMessage id="TERMS_MOBILE_NETWORKS_L1b" />
          <br />
          <br />
          {/* 3 */}
          <li><strong><FormattedMessage id="TERMS_YOUR_CONTENT_AND_ACCOUNT" /></strong></li>
          <u><FormattedMessage id="TERMS_USER_GENERATED_CONTENT" /></u>
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L1a" />
          {' '}
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L1b" />
          {' '}
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L1c" />
          <br />
          <br />
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L2" />
          <br />
          <br />
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L3a" />
          {' '}
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L3b" />
          {' '}
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L3c" />
          <br />
          <br />
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L4a" />
          {' '}
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L4b" />
          <br />
          <br />
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L5a" />
          {' '}
          <FormattedMessage id="TERMS_USER_GENERATED_CONTENT_L5b" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_ACCOUNTS" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_ACCOUNTS_L1a" />
          {' '}
          <FormattedMessage id="TERMS_ACCOUNTS_L1b" />
          {' '}
          <FormattedMessage id="TERMS_ACCOUNTS_L1c" />
          {' '}
          <FormattedMessage id="TERMS_ACCOUNTS_L1d" />
          <br />
          <br />
          <FormattedMessage id="TERMS_ACCOUNTS_L2a" />
          {' '}
          <FormattedMessage id="TERMS_ACCOUNTS_L2b" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_PASSWORDS_AND_SECURITY" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_PASSWORDS_AND_SECURITY_L1a" />
          {' '}
          <FormattedMessage id="TERMS_PASSWORDS_AND_SECURITY_L1b" />
          <br />
          <br />
          <FormattedMessage id="TERMS_PASSWORDS_AND_SECURITY_L2a" />
          {' '}
          <FormattedMessage id="TERMS_PASSWORDS_AND_SECURITY_L2b" />
          <br />
          <br />
          {/* 4 */}
          <li><strong><FormattedMessage id="TERMS_ADDITIONAL_PROVISIONS" /></strong></li>
          <br />
          <br />
          <u><FormattedMessage id="TERMS_CLAIMS_OF_COPYRIGHT_INFRINGEMENT" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_CLAIMS_OF_COPYRIGHT_INFRINGEMENT_L1" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L1a" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L1b" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L1c" />
          <br />
          <br />
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L2a" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L2b" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L2c" values={{ numberOfDaysToNegotiation }} />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L2d" values={{ numberOfDaysToNegotiation }} />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L2e" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L2f" />
          <br />
          <br />
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L3a" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L3b" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L3c" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L3d" values={{ adrUrl, adrPhoneNumber }} />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L3e" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L3f" />
          <br />
          <br />
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L4" />
          <br />
          <br />
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L5a" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L5b" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L5c" />
          {' '}
          <FormattedMessage id="TERMS_BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER_L5d" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_CHOICE_OF_FORUM" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_CHOICE_OF_FORUM_L1" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_CHOICE_OF_LAW" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_CHOICE_OF_LAW_L1" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_SEVERABILITY" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_SEVERABILITY_L1" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_SURVIVAL" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_SURVIVAL_L1" />
          <br />
          <br />
          <u><FormattedMessage id="TERMS_WAIVER" /></u>
          <br />
          <br />
          <FormattedMessage id="TERMS_WAIVER_L1" />
        </ol>
        <br />
        <br />
      </Box>
    </DefaultLayout>
  );
}
