// import logo from '../images/logo.svg';
import React from 'react';
import { getLangDir } from 'rtl-detect';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import {
  aboutPage, cookieUsePage, faqPage, homePage, privacyPolicyPage, termsOfUsePage,
} from '../Contants/pages';

import AboutPage from '../Pages/AboutPage';
import FaqPage from '../Pages/FaqPage';
import PrivacyPolicyPage from '../Pages/PrivacyPolicyPage';
import TermsOfUsePage from '../Pages/TermsOfUsePage';
import CookieUsePage from '../Pages/CookieUsePage';
import HomePage from '../Pages/HomePage';
import NotFound404Page from '../Pages/NotFound404Page';
import { getBrowserLang } from '../utils/lang-util';

const lang = getBrowserLang();
const direction = getLangDir(lang);
// TODO: normalize user lang to match massages.
const messages = require('../i18n/strings.json');

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () => createTheme({
      direction,
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
      },
    }),
    [prefersDarkMode],
  );

  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
  });

  return (
    <IntlProvider locale={lang} messages={messages}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div dir={direction} className="app">
            <BrowserRouter>
              <Routes>
                <Route path={aboutPage} element={<AboutPage />} />
                <Route path={faqPage} element={<FaqPage />} />
                <Route path={privacyPolicyPage} element={<PrivacyPolicyPage />} />
                <Route path={termsOfUsePage} element={<TermsOfUsePage />} />
                <Route path={cookieUsePage} element={<CookieUsePage />} />
                <Route path={homePage} element={<HomePage />} />
                <Route path="*" element={<NotFound404Page />} />
              </Routes>
            </BrowserRouter>
          </div>
        </ThemeProvider>
      </CacheProvider>
    </IntlProvider>
  );
}

export default App;

/*
<header className="app-header">
        <h1>Adam TV</h1>

        <div>
          <a
            className="app-link"
            href="https://reactjs.org"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a> | <a
            className="app-link"
            href="https://reactjs.org"
            rel="noopener noreferrer"
          >
            Terms of use
          </a>
        </div>
      </header>
*/
