import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DefaultLayout from '../../Layout/DefaultLayout';

const caCivilCodeSectionNumber = '1798.83';
const caBusinessAndProfessionsCodeSection = '22581';
const childrenUnderAge = '13';

export default function PrivacyPolicyPage() {
  return (
    <DefaultLayout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h3" component="h1" align="center">
          <FormattedMessage id="ADAMTV_PRIVACY_POLICY" />
        </Typography>
        <Typography align="center" m={1} mb={4}>
          <FormattedMessage id="LAST_UPDATED" />
          :  Jan 9, 2022
        </Typography>
        <ol>
          {/* 1 */}
          <li>
            <strong><FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT_INTROa" />
            {' '}
            <FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT_INTROb" />
            <br />
            <br />
            <ul>
              <li><FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT_L1" /></li>
              <li><FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT_L2" /></li>
              <li><FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT_L3" /></li>
              <li><FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT_L4" /></li>
              <li><FormattedMessage id="PP_TYPES_OF_INFO_WE_COLLECT_L5" /></li>
            </ul>
          </li>
          <br />
          <br />
          {/* 2 */}
          <li>
            <strong><FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO" /></strong>
            <br />
            <br />
            <ul>
              <li>
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L1a" />
                {' '}
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L1b" />
                {' '}
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L1c" />
                {' '}
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L1d" />
              </li>
              <li>
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L2a" />
                {' '}
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L2b" />
              </li>
              <li>
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L3a" />
                {' '}
                <FormattedMessage id="PP_HOW_WE_COLLECT_YOUR_INFO_L3b" />
              </li>
            </ul>
          </li>
          <br />
          <br />
          {/* 3 */}
          <li>
            {/* FIXME: text block may not be right */}
            <strong><FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_INTRO" />
            <br />
            <br />
            <ul>
              <li><FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L1" /></li>
              <li><FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L2" /></li>
              <li>
                <FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L3" />
                <ul>
                  <li><FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L3_1" /></li>
                  <li><FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L3_2" /></li>
                  <li>
                    <FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L3_3a" />
                    {' '}
                    <FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L3_3b" />
                  </li>
                </ul>
              </li>
              <li><FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L4" /></li>
              <li><FormattedMessage id="PP_USE_OF_YOUR_INFO_BY_COMPANY_L5" /></li>
            </ul>
          </li>
          <br />
          <br />
          {/* 4 */}
          <li>
            {/* FIXME: text block may not be right */}
            <strong><FormattedMessage id="PP_SHARING_YOUR_INFO_WITH_OTHER_COMPANIES" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_SHARING_YOUR_INFO_WITH_OTHER_COMPANIES_INTRO" />
            <br />
            <br />
            <ul>
              <li>
                <FormattedMessage id="PP_SHARING_YOUR_INFO_WITH_OTHER_COMPANIES_L1" />
                <ul>
                  <li><FormattedMessage id="PP_SHARING_YOUR_INFO_WITH_OTHER_COMPANIES_L1_1" /></li>
                  <li><FormattedMessage id="PP_SHARING_YOUR_INFO_WITH_OTHER_COMPANIES_L1_2" /></li>
                </ul>
                <br />
                <br />
                <span className="small"><FormattedMessage id="PP_SHARING_YOUR_INFO_WITH_OTHER_COMPANIES_NOTE" /></span>
              </li>
            </ul>
          </li>
          <br />
          <br />
          {/* 5 */}
          <li>
            {/* FIXME: text block may not be right */}
            <strong><FormattedMessage id="PP_CHILDRENS_PRIVACY" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_CHILDRENS_PRIVACY_INTROa" />
            {' '}
            <FormattedMessage id="PP_CHILDRENS_PRIVACY_INTROb" />
            {' '}
            <FormattedMessage id="PP_CHILDRENS_PRIVACY_INTROc" />
            <br />
            <br />
            <ul>
              <li><FormattedMessage id="PP_CHILDRENS_PRIVACY_L1" /></li>
              <li><FormattedMessage id="PP_CHILDRENS_PRIVACY_L2" /></li>
              <li><FormattedMessage id="PP_CHILDRENS_PRIVACY_L3" /></li>
              <li><FormattedMessage id="PP_CHILDRENS_PRIVACY_L4" /></li>
            </ul>
            <br />
            <br />
            <span className="small"><FormattedMessage id="PP_CHILDRENS_PRIVACY_NOTE" /></span>
          </li>

          <br />
          <br />
          {/* 6 */}
          <li>
            <strong><FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROa" />
            {' '}
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROb" />
            {' '}
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROc" />
            {' '}
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROd" />
            {' '}
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROe" />
          </li>
          <br />
          <br />

          {/* 7 */}
          <li>
            {/* FIXME: text block may not be right */}
            <strong><FormattedMessage id="PP_DATA_TRANSFERS_STORAGE_AND_PROCESSING_GLOBLLY" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROa" />
            {' '}
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROb" />
            {' '}
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROc" />
            {' '}
            <FormattedMessage id="PP_DATA_SECURITY_INTEGRITY_AND_RETENTION_INTROd" />
          </li>
          <br />
          <br />

          {/* 8 */}
          <li>
            <strong><FormattedMessage id="PP_CHANGES_TO_THIS_PRIVACY_POLICY" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_CHANGES_TO_THIS_PRIVACY_POLICY_INTROa" />
            {' '}
            <FormattedMessage id="PP_CHANGES_TO_THIS_PRIVACY_POLICY_INTROb" />
          </li>
          <br />
          <br />
          {/* 9 */}
          <li>
            <strong><FormattedMessage id="PP_COMMENTS_AND_QUESTIONS" /></strong>
            <br />
            <br />
            <FormattedMessage id="PP_COMMENTS_AND_QUESTIONS_INTROa" />
            {' '}
            <FormattedMessage id="PP_COMMENTS_AND_QUESTIONS_INTROb" />
            {' '}
            <FormattedMessage id="PP_COMMENTS_AND_QUESTIONS_INTROc" />
            <br />
            <br />
            <u><FormattedMessage id="PP_COMMENTS_AND_QUESTIONS_CALIFORNIA" /></u>
            {' '}
            <FormattedMessage id="PP_COMMENTS_AND_QUESTIONS_CALIFORNIA_INTROa" />
            {' '}
            <FormattedMessage id="PP_COMMENTS_AND_QUESTIONS_CALIFORNIA_INTROb" values={{ caCivilCodeSectionNumber }} />
            {' '}
            <FormattedMessage id="PP_COMMENTS_AND_QUESTIONS_CALIFORNIA_INTROc" values={{ caBusinessAndProfessionsCodeSection }} />
          </li>
        </ol>
        <br />
        <br />
        <strong><FormattedMessage id="PP_DEFINITIONS" /></strong>
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_AGGREGATE_INFORMATION" />
          :
        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_AGGREGATE_INFORMATION_DESC" />
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_ANONYMOUS_INFORMATION" />
          :
        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_ANONYMOUS_INFORMATION_DESC" />
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_APPLICATION" />
          :

        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_APPLICATION_DESC" />
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_CHILDREN" />
          :
        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_CHILDREN_DESCa" />
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_CHILDREN_DESCb" values={{ childrenUnderAge }} />
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_IP_ADDRESS" />
          :
        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_IP_ADDRESS_DESCa" />
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_IP_ADDRESS_DESCb" />
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_NOTICE" />
          :
        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_NOTICE_DESC" />
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_PARENTS" />
          :
        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_PARENTS_DESC" />
        <br />
        <br />
        <u>
          <FormattedMessage id="PP_DEFINITIONS_PERSONAL_INFORMATION" />
          :
        </u>
        {' '}
        <FormattedMessage id="PP_DEFINITIONS_PERSONAL_INFORMATION_DESCa" />
        <FormattedMessage id="PP_DEFINITIONS_PERSONAL_INFORMATION_DESCb" />
        <br />
        <br />
      </Box>
    </DefaultLayout>
  );
}
