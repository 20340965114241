/* eslint-disable react/prop-types */
import queryString from 'query-string';
import SiteHeader from '../../Components/SiteHeader';
import SiteFooter from '../../Components/SiteFooter';

export default function DefaultLayout({ children }) {
  const { isApp } = queryString.parse(window.location.search);

  const skipLayout = isApp === 'true';

  if (skipLayout) {
    return children;
  }

  return (
    <div>
      <SiteHeader />
      {children}
      <SiteFooter />
    </div>
  );
}
