import { FormattedMessage } from 'react-intl';
import {
  Link,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { cookieUsePage, privacyPolicyPage, termsOfUsePage } from '../../Contants/pages';

export default function SiteFooter() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Link to={privacyPolicyPage}><FormattedMessage id="PRIVACY_POLICY" /></Link>
      <Typography m={2}>|</Typography>
      <Link to={termsOfUsePage}><FormattedMessage id="TERMS_OF_USE" /></Link>
      <Typography m={2}>|</Typography>
      <Link to={cookieUsePage}><FormattedMessage id="COOKIE_USE" /></Link>
    </Box>
  );
}
